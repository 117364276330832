import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
import { navigate } from 'gatsby';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const Button = makeShortcode("Button");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "supporting-your-teens-online-learning"
    }}>{`Supporting your teen’s online learning`}</h2>
    <Box display="flex" justifyContent="center" mdxType="Box">
  <Button onClick={() => navigate('/m2-parenting-in-pandemic/22-loved-one')} variant="contained" color="primary" mdxType="Button">
    Skip Topic
  </Button>
    </Box>
    <p>{`Like it or not, for many young people, online learning is one of the cards they’ll be dealt during the the pandemic. Although this is out of your control as a parent, there are things you can do to help make the online learning experience more manageable for your teen, and in turn, for you.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m2/11-online-learning.svg" alt="online classroom" mdxType="SingleImage" />
    <p>{`This section will cover:`}</p>
    <ul>
      <li parentName="ul">{`Supporting online learning`}</li>
      <li parentName="ul">{`Transitioning back to face-to-face classes`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      